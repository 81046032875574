<template>
  <el-row :gutter="20">
    <el-col
      :sm="10"
      class="sticky"
    >
      <Shower
        :active-input="activeInput"
      />
    </el-col>
    <el-col :sm="14">
      <el-form>
        <el-collapse
          v-model="activeStep"
          accordion
        >
          <el-collapse-item
            :name="componentList.Format"
          >
            <template slot="title">
              <h3>{{ $t('format') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Format"
                class="status-icon"
                icon="check"
              />
            </template>

            <div>
              <span>{{ $t('fittingTitle') }}:</span>
              <div style="margin-bottom: 12px">
                <el-radio 
                  v-model="fitting" 
                  label="left" 
                  border 
                  size="medium"
                >
                  {{ $t('left') }}
                </el-radio>
                <el-radio 
                  v-model="fitting" 
                  label="right" 
                  border 
                  size="medium"
                >
                  {{ $t('right') }}
                </el-radio>
              </div>
            </div>

            <validation-observer
              ref="formatObserver"
              v-slot="{ invalid }"
            >
              <Format
                :possible-formats="[]"
                @validate="validateGlassWidth()"
              />

              <div align="right">
                <el-button
                  type="primary"
                  :disabled="invalid"
                  @click="nextStep(1)"
                >
                  {{ $t('nextBtn') }}
                </el-button>
              </div>
            </validation-observer>
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Corner"
            :disabled="activeStep < componentList.Corner"
          >
            <template slot="title">
              <h3>Abgerundete Ecke</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Corner"
                class="status-icon"
                icon="check"
              />
            </template>
            <CheckboxGroup
              :default-value="corners.oben_links"
              :data="[
                {
                  id: 1,
                  name: 'Kleine Rundung',
                  body_html: '<p>Bei der kleinen Rundung beträgt der Radius <b>20 mm</b>. Dadurch entsteht eine dezente Abrundung der Ecke.</p>',
                  img: getImageName('round-edge'),
                  value: { name: 'rund', x: 0, y: 0, r: 20 }
                }, {
                  id: 2,
                  name: 'Große Rundung',
                  body_html: '<p>Bei der großen Rundung beträgt der Radius <b>100 mm</b>. Dadurch entsteht eine weiche Abrundung der Ecke.</p>',
                  img: getImageName('biground-edge'),
                  value: { name: 'rund', x: 0, y: 0, r: 100 }
                }
              ]"
              @update:selection="fitting == 'left' ?
                corners.oben_rechts = $event : 
                corners.oben_links = $event"
              @nextStep="nextStep($event)"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Color"
            :disabled="activeStep < componentList.Color"
          >
            <template slot="title">
              <h3>{{ $t('color') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Color"
                class="status-icon"
                icon="check"
              />
            </template>
            <span>{{ $t('colorInfo') }}:</span>
            <Farbe
              :colors="colors"
              @nextStep="nextStep($event)"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Hinge"
            :disabled="activeStep < componentList.Hinge"
          >
            <template slot="title">
              <h3>{{ $t('hinge') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Hinge"
                class="status-icon"
                icon="check"
              />
            </template>
            <Scharnier
              :connection="'Glas-Wand'"
              @nextStep="nextStep($event)"
              @activeInput="setActiveInput"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Extras"
            :disabled="activeStep < componentList.Extras"
          >
            <template slot="title">
              <h3>{{ $t('extras') }}</h3>
            </template>
            <Extras />
            <div align="right">
              <el-button
                type="primary"
                data-iframe-height
                :loading="loading"
                @click="checkout"
              >
                {{ chkoutBtnText }}
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import Shower from "@/components/Layout/Shower/Bathtub/Index.vue";
import Format from '@/components/Layout/Format.vue';
import CheckboxGroup from "@/components/CheckboxGroup.vue";
import Farbe from '@/components/Gestaltung/Farbe.vue';
import Scharnier from '@/components/Shower/Scharnier.vue';
import Extras from "@/components/Extras.vue";
import i18n from "@/js/plugins/i18n";

export default {
  name: "BadewannenKonfigurator",
  components: {
    Shower,
    Format,
    CheckboxGroup,
    Farbe,
    Scharnier,
    Extras,
  },
  props: {
    collectionId: {
      type: String,
      default: ""
    },
    whatIf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentList: {
        Format: 1,
        Corner: 2,
        Color: 3,
        Hinge: 4,
        Extras: 5
      },
      chkoutBtnText: this.$t('chkoutDialogBtn'),
      errorDialog: false,
      loading: false,
      activeInput: ""
    };
  },
  computed: {
    ...mapGetters([
      "getMaxWidth",
    ]),
    ...mapFields([
      "activeStep",
      "configId",
      "corners",
      "fitting",
      "length",
      "height",
      "minWidth",
      "productTitle",
      "selectedEdge"
    ]),
    colors() {
      const tuple = [['type', ['Grundprofil', 'Scharnier']]];
      const accessories = this.$store.getters.filterAccessoriesBy(tuple);
      const colors = this.$store.getters.getAccessorySubsetBy('color', accessories);

      return [...new Set(colors.flat())];
    }
  },
  watch: {
    fitting() {
      if (this.fitting === 'left') {
        this.corners.oben_rechts = this.corners.oben_links;
        this.corners.oben_links = this.corners.oben_mitte;
      } else {
        this.corners.oben_links = this.corners.oben_rechts;
        this.corners.oben_rechts = this.corners.oben_mitte;
      }
    },
    activeStep(newVal, oldVal) {
      if (!newVal && oldVal > 1) {
        this.activeStep = oldVal - 1;
      }
      this.$emit("datachange");
    }
  },
  created() {
    this.getAdditionalAccessoryProducts();
    this.selectedEdge = i18n.t('edgeHighGlossTitle');

    this.$store.commit("addHinge", 250);
    this.$store.commit("addHinge", this.height - 250);
  },
  methods: {
    getAdditionalAccessoryProducts() {
      if (this.collectionId) {
        this.errorDialog = false;
        this.$http
          .get(`${process.env.VUE_APP_GET_PRODUCTS_BY_COLID}${this.collectionId}`)
          .then(response => {
            this.$store.commit("addAdditionalAccessoryProducts", response.data);
            this.$emit("loadSavedConfig");
          })
          .catch(() => {
            this.errorDialog = true;
          });
      }
    },
    setActiveInput(name) {
      this.activeInput = name;
    },
    getImageName(type) {
      if (/getönt/i.test(this.productTitle)) {
        return `dark-${type}`;
      } else if (/satiniert/i.test(this.productTitle)) {
        return `light-${type}`;
      } else {
        return `transparent-${type}`;
      }
    },
    nextStep() {
      this.activeStep = Object.values(this.componentList)[this.activeStep];
    },
    checkout() {
      this.loading = true;
      this.chkoutBtnText = this.$t('chkoutDialogBtnLoading');
      const items = this.$store.getters.getShowerGlassLineItems;

      if (this.whatIf) {
        this.$emit("postLineItems", null, items);
        this.chkoutBtnText = this.$t('chkoutDialogBtn'),
        this.loading = false;
      } else {
        this.$http
          .post(
            process.env.VUE_APP_CHECKOUT,
            this.$store.getters.getShowerGlassProduct
          )
          .then(response => {
            this.$emit("postLineItems", response.data, items);
          }).catch(() => {
            this.$emit('notify', {
              title: this.$t('cartError'),
              message: this.$t('addToCartError'),
              type: 'error'
            });
          }).finally(() => {
            this.chkoutBtnText = this.$t('chkoutDialogBtn'),
            this.loading = false;
          });
      }
    },
    validateGlassWidth() {
      this.$nextTick(() => {
        this.$refs.formatObserver.refs.widthValidator.validate();
      })
    }
  }
}
</script>
