<template>
  <div>
    <v-stage
      ref="stage"
      :config="stageSize"
    >
      <v-layer v-if="showStage()">
        <Cabin
          v-if="showStage()"
          :x="x + offset.x"
          :y="y + offset.y"
          :length="fixedStageWidth"
          :height="height + bathtubOffset"
          :offset="bathtubOffset"
          :wall="wall"
          :flip="fitting == 'right'"
          :pos="fitting"
          :ratio="ratio"
          :farbe="farbe"
          :input-length="length"
          :input-height="height"
        />

        <!--
          -------------------------------------------------------------------------
          D O O R   S T A R T
        -->
        <Glass
          :x="x + offset.x + ((fitting == 'right' ? fixedStageWidth - length - 60 : hinge.offset + wall.thickness) * ratio)"
          :y="y + offset.y"
          :length="realLength * ratio"
          :height="realHeight * ratio"
          :input-length="realLength"
          :input-height="realHeight"
          :active="!activeInput.startsWith('hinge_')"
          :color="glassColor"
          :corners="getCorners()"
          :lines="glassDesign"
          :line-x="x + offset.x + ((wall.thickness + hinge.offset) * ratio)"
          :scale="ratio"
          tag-color="#ddd"
        />

        <v-group
          v-if="selectedHinge && /Grundprofil|Klemmschiene/i.test(selectedHinge.type)"
        >
          <Rail
            :x="x + offset.x + ((fitting == 'left' ? wall.thickness : fixedStageWidth - wall.dimensision) * ratio)"
            :y="y + offset.y"
            :height="height * ratio"
            :material="farbe"
            :thickness="19 * ratio"
          />
        </v-group>
        <v-group v-else>
          <Hinge
            v-for="(h, index) in hingePositions"
            :key="`hinge_${index}`"
            :x="fitting == 'left' ? 
              x + offset.x + (wall.thickness * ratio) :
              x + offset.x + ((fixedStageWidth - 60) * ratio)"
            :y="y + offset.y + (h.y * ratio)"
            :label="h.y"
            :offset-y="offset.y"
            :active="activeInput == `hinge_${index}`"
            :flip="fitting !== 'left'"
            :scale="ratio"
            :form="hinge.form"
            :material="farbe"
            :type="'angle'"
            :connection="'wall'"
          />
        </v-group>
        <!--
          -------------------------------------------------------------------------
          D O O R   E N D
        -->
      </v-layer>
    </v-stage>
    <el-alert
      style="margin-top: 12px"
      type="info"
      center
      show-icon
      :closable="false"
    >
      {{ $t('glassDoorDimensision') }} {{ realLength }} x {{ realHeight }} mm
    </el-alert>
  </div>
</template>
<script>
import Cabin from './Cabin.vue';
import Glass from '../Parts/Glass.vue';
import Hinge from '../Parts/Hinge.vue';
import Rail from '../Parts/Rail.vue';
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Shower",
  components: {
    Cabin,
    Glass,
    Hinge,
    Rail
  },
  props: {
    activeInput: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fixedStageWidth: 1900,
      bathtubOffset: 500,
      timeout: null,
      x: 0,
      y: 0,
      offset: {
        x: 20,
        y: 20
      },
      wall: {
        thickness: 40,
        dimensision: 100
      },
      hinge: {
        form: 'quadrat',
        offset: 2
      },
      stageSize: {
        width: 0,
        height: 0,
      },
      ratio: 1,
      weight: 0,
    };
  },
  computed: {
    ...mapGetters([
      "calculateShowerGlassWidth",
      "calculateShowerGlassHeight",
      "getMaxWidth"
    ]),
    ...mapFields([
      "corners",
      "farbe",
      "fitting",
      "glassColor",
      "glassDesign",
      "minWidth",
      "minHeight",
      "maxHeight",
      "length",
      "height",
      "hingePositions",
      "selectedHinge"
    ]),
    realLength() {
      return this.calculateShowerGlassWidth;
    },
    realHeight() {
      return this.calculateShowerGlassHeight;
    }    
  },
  watch: {
    length() {
      this.lazyCaller(this.updateCanvas());
    },
    height() {
      this.lazyCaller(this.updateCanvas());
    },
    selectedHinge() {
      this.hinge.offset = this.selectedHinge?.offset || 2;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setStageWidth();
      this.lazyCaller(this.updateCanvas());
    });
  },
  created() {
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    getCorners() {
      const corners = Object.values(this.corners).map(corner => corner.r * this.ratio);
      corners.splice(1, 1);
      return corners;
    },
    lazyCaller(callback, time = 150) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(callback, time)
    },
    showStage() {
      const valid = this.length && this.minWidth && this.getMaxWidth &&
        this.height && this.minHeight && this.maxHeight &&
        parseInt(this.length) >= parseInt(this.minWidth) &&
        parseInt(this.length) <= parseInt(this.getMaxWidth) && 
        parseInt(this.height) >= parseInt(this.minHeight) && 
        parseInt(this.height) <= parseInt(this.maxHeight) && 
        parseFloat(this.ratio) > 0;

      return valid;
    },
    getStageHeight() {
      return window.innerWidth <= 460 ? 300 : 
        window.innerWidth <= 768 ? 450 : 580;
    },
    setStageWidth() {
      this.stageSize.width = this.$refs.stage ? this.$refs.stage.$el.clientWidth : 0;
    },
    updateCanvas() {
      const label = { height: 30, width: 120 };
      this.stageSize.height = this.getStageHeight();
      var frameWidth = this.fixedStageWidth;
      var frameHeight = parseInt(this.height + this.bathtubOffset);

      var wr = (this.stageSize.width - label.width) / frameWidth || 0;
      var hr = (this.stageSize.height - label.height) / frameHeight || 0;

      var ratio = Math.min(wr, hr);
      this.ratio = Math.abs(ratio);
      this.stageSize.height = (this.height + this.bathtubOffset) * this.ratio + label.height;

      const drawing_width = this.fixedStageWidth * this.ratio;
      this.offset.x = (this.stageSize.width - drawing_width) / 2;
    },
    resizeCanvas() {
      this.setStageWidth();
      this.lazyCaller(this.updateCanvas());
    }
  }
}
</script>
